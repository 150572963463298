import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Container from "../components/container";

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="404 - Siden findes ikke" />
        <Hero title="Siden findes ikke" image />
        <Container>
          <p>Denne side findes ikke eller er flyttet - beklager ulejligheden :-)</p>
        </Container>
      </Layout>
    )
  }
}

export default Page

